export const CALL_SIGN_IN_METHOD = "auth/CALL_SIGN_IN_METHOD"
export const SIGN_IN_SUCCESS = "auth/SIGN_IN_SUCCESS"
export const SIGN_IN_ERROR = "auth/SIGN_IN_ERROR"
export const SIGN_IN_RESET = "auth/SIGN_IN_RESET"
export const CALL_LOGOUT_METHOD = "auth/CALL_LOGOUT_METHOD"
export const CALL_LOGOUT_METHOD_SUCCESS = "auth/CALL_LOGOUT_METHOD_SUCCESS"
export const CALL_SIGN_UP_METHOD = "auth/CALL_SIGN_UP_METHOD"
export const LOG_OUT = "LOG_OUT"
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS"
export const UPDATE_USER_INFO_AUTH = "UPDATE_USER_INFO_AUTH"
