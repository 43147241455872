import API from "../../configs/api"
import apiMethod from "@utility/ApiMethod"

export const updateInfoUser = ({ payload, codeLanguage }) => {
  return apiMethod.put(codeLanguage + API.UPDATE_USER_INFO, payload);
};

export const getInfoUser = ({ payload, codeLanguage }) => {
  return apiMethod.post(
    codeLanguage + API.GET_ACCOUNT_PROFILE + `?useId=${payload?.userId}`, payload
  )
}

export const getGeneralInfoMenu = ({ payload, codeLanguage }) => {
  return apiMethod.get(codeLanguage + API.GET_GENERAL_INFO_MENU, payload)
}
